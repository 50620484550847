<template>
	<b-form-checkbox
		v-model="switchValue"
		unchecked-value="pending"
		value="rejected"
		name="check-button"
		switch
	>
		Showing:
		<b>
			{{ switchValue.charAt(0).toUpperCase() + switchValue.slice(1) }}
		</b>
	</b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';

export default {
	components: {
		BFormCheckbox,
	},
	props: {
		productTypeSwitch: {
			type: String,
			default: 'pending',
		},
	},
	computed: {
		switchValue: {
			get() {
				return this.productTypeSwitch;
			},
			set(val) {
				this.$emit('update:productTypeSwitch', val);
			},
		},
	},
};
</script>

<style></style>
